// main.js
function showModalSignUp(){
	$(".formSignUpModal").show();
	$(".formSignInModal").hide();
	$(".pp-text").text("signing up");
}

function showModalSignIn(){
	$(".formSignInModal").show();
	$(".formSignUpModal").hide();
	$(".pp-text").text("logging in");
}

function showLoginModal(){
	$("#alertModal").modal('hide');

	setTimeout(function() {
       $("#btnSignInNav").trigger('click');
		$(".formSignInModal").show();
		$(".formSignUpModal").hide();
		$(".pp-text").text("logging in");
	}, 400);//delay in miliseconds##1000=1second
}

function shareLink(tp){
	var link = $("#linkShare").val();
	var sbj = $("#subjectShare").val();

	if(tp == "FB"){
		window.open("https://www.facebook.com/sharer/sharer.php?u=" + link,'targetWindow','toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=250');
	}else if(tp == "EMAIL"){
		window.location.href = "mailto:?subject=" + sbj + "&body=Check out the detail here: " + link;
	}
}

function shareThis(title, subject, link){
	$(".title-share").text(title);
	$("#linkShare, #linkShareDisplay").val(link);
	$("#subjectShare").val(subject);
	$("#btnShareClicker").trigger('click');
}

$(document).ready(function(){
	/** NAVIGATION **/
	var trigger = $('.hamburger'),
		overlay = $('.overlay'),
		isClosed = false,
		triggerMenu =  $('.close-side');

	trigger.click(function () {
		hamburger_cross();      
	});

	triggerMenu.click(function () {
		$('.hamburger').trigger('click');     
	});

	function hamburger_cross() {
		if (isClosed == true) {          
			overlay.hide();
			trigger.removeClass('is-open');
			trigger.addClass('is-closed');
			isClosed = false;
		} else {   
			overlay.show();
			trigger.removeClass('is-closed');
			trigger.addClass('is-open');
			isClosed = true;
		}
	}

	$('[data-toggle="offcanvas"]').click(function () {
		$('#wrapper').toggleClass('toggled');
	});

	$("#btnGoToSignUpModal, .sign-up-btn").click(function(){
		showModalSignUp();
	});

	$("#btnGoToSignInModal, .sign-in-btn").click(function(){
		showModalSignIn();
	});

	$("#signInLeftMenu").click(function(){
		$('.hamburger').trigger('click'); 
		showModalSignIn();
	});

	$("#signUpLeftMenu").click(function(){
		$('.hamburger').trigger('click'); 
		showModalSignUp();
	});

	//Signup:
	$("#btnSignUpModal").click(function(){
		$(this).attr("disabled", "disabled");
		$(".error-wrapper").hide();
		$(".error-wrapper").empty();
		$.ajax({
		    url: $("#doSignUpUrl").val(),
		    type: 'post',
		    data: {
		    	email: $("#txtSignUpEmailModal").val(),
		    	password: $("#txtSignUpPasswordModal").val(),
		    	lasturl: $("#lastUrl").val()
		    },
		    headers: {
		    	'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		    },
		    dataType: 'json',
		    success: function (data) {
		    	$("#btnSignUpModal").removeAttr("disabled");
		    	if(data.status == "ok"){
		    		window.location.href = data.redirecturl;
		    	}else{
		    		$(".error-wrapper").show();
		    		$(".error-wrapper").append('<li>Failed to login, please try again</li>');
		    	}
		    },
		    error: function(err) {
		    	$("#btnSignUpModal").removeAttr("disabled");

		    	if(err.status == 422){
		    		$(".error-wrapper").show();
		    		var msgs = JSON.parse(err.responseText);
		    		$.each(msgs, function(i, obj){
		    			$.each(obj, function(i, msg){
		    				$(".error-wrapper").append('<li>' + msg + '</li>');
		    			});
		    		});
		    	}
		    }
		});
	});

	//Sign In:
	$("#btnSignInModal").click(function(){
		$(this).attr("disabled", "disabled");
		$(".error-wrapper").hide();
		$(".error-wrapper").empty();
		$.ajax({
		    url: $("#doSignInUrl").val(),
		    type: 'post',
		    data: {
		    	email: $("#txtSignInEmailModal").val(),
		    	password: $("#txtSignInPasswordModal").val(),
		    	lasturl: $("#lastUrl").val()
		    },
		    headers: {
		    	'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		    },
		    dataType: 'json',
		    success: function (data) {
		    	$("#btnSignInModal").removeAttr("disabled");
		    	if(data.status == "ok"){
		    		window.location.href = data.redirecturl;
		    	}else{
		    		$(".error-wrapper").show();
		    		$(".error-wrapper").append('<li>Failed to login, please try again</li>');
		    	}
		    },
		    error: function(err) {
		    	$("#btnSignInModal").removeAttr("disabled");

		    	if(err.status == 422){
		    		$(".error-wrapper").show();
		    		var msgs = JSON.parse(err.responseText);
		    		$.each(msgs, function(i, obj){
		    			$.each(obj, function(i, msg){
		    				$(".error-wrapper").append('<li>' + msg + '</li>');
		    			});
		    		});
		    	}
		    }
		});
	});

	//Search:
	var searchObj = $('#songSearch, #songSearchMobile').selectize({
        valueField: 'slug',
        labelField: 'title',
        searchField: ['title', 'artist'],
        options: [],
        create: false,
        maxItems: 1,
        render: {
            option: function(item, escape) {
                return '<div><div>' + escape(item.title) + '</div><div style="font-size:10px;">' + escape(item.artist) + '</div></div>';
            }
        },
        load: function(query, callback) {
            if (!query.length) return callback();
            $(".search-loader").show();
            $(".nav-search-loader").show();
            $.ajax({
                url: $("#searchUrl").val(),
                type: 'GET',
                dataType: 'json',
                data: {
                    search: query
                },
                error: function() {
                    callback();
                },
                success: function(res) {
                    $(".search-loader").hide();
                    $(".nav-search-loader").hide();
                    callback(res.data);
                }
            });
        },
        onChange: function(){
            if(this.items[0] != undefined){
            	window.location.href = $("#domainUrl").val() + '/songs/details/' + this.items[0];
            }
        }
    });

	var selectize = searchObj[0].selectize;

	$("#songSearch-selectized, #songSearchMobile-selectized").focus(function(){
		selectize.clear();
	});
});